import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { formatCurrency } from '../../../utils/valueFormatters';

export default function CashLogBankNoteComponent(props: any) {
  const { bankNote, onChange } = props;
  const [amount, setAmount] = React.useState<any>('');
  const [result, setResult] = React.useState<any>('');

  useEffect(() => {
    if (amount) {
      setResult(bankNote * amount);
      onChange(bankNote, amount);
    }
  }, [amount, bankNote]);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item>
        <TextField
          fullWidth
          size="small"
          variant="standard"
          value={formatCurrency(bankNote)}
          disabled
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          placeholder="Darabszám"
          type="number"
          size="small"
          variant="standard"
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value))}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          size="small"
          placeholder="Összesen"
          variant="standard"
          value={formatCurrency(result)}
          disabled
        />
      </Grid>
    </Grid>
  );
}
