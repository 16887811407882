import React, { useEffect, useState } from 'react';
import Timer from '../components/Timer';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';

const useSession = (sessionTimeout: number) => {
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  const resetTime = () => setElapsedSeconds(0);
  const logout = () => window.location.replace(`/api/account/logout`);

  const SessionTimer = () => {
    useEffect(() => {
      document.addEventListener('onkeydown', resetTime);
      document.addEventListener('mousedown', resetTime);

      return () => {
        document.removeEventListener('onkeydown', resetTime);
        document.removeEventListener('mousedown', resetTime);
      };
    }, []);

    if (sessionTimeout === 0) return null;

    const handleElapsedTime = () => {
      setElapsedSeconds((state) => {
        if (state + 1 === sessionTimeout * 60 - 5) {
          Swal.fire({
            title:
              'Munkamenet 5 perc mulva lejár. Minden nem mentett változtatás elvész!',
            icon: 'warning',
            confirmButtonText: 'Meghosszabbítás',
            showCancelButton: true,
            cancelButtonText: 'Kijelentkezés',
            cancelButtonColor: 'red',
          }).then((result) => {
            if (result.isConfirmed) {
              resetTime();
            }

            if (result.dismiss === Swal.DismissReason.cancel) {
              logout();
            }
          });
        }

        return state + 1;
      });
    };

    if (isNaN(sessionTimeout)) {
      return null;
    }

    return (
      <>
        <Grid item sx={{ display: { xs: 'none', md: 'inherit' } }}>
          Munkamenet:
        </Grid>
        <Grid item sx={{ pl: 1 }}>
          <Timer
            countFromSeconds={sessionTimeout * 60}
            elapsedSeconds={elapsedSeconds}
            onElapsedSeconds={handleElapsedTime}
            onCountDownOver={logout}
          />
        </Grid>
      </>
    );
  };

  return { SessionTimer };
};

export default useSession;
