import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Print } from '@mui/icons-material';
import usePrintComponent from '../../hooks/usePrintComponent';
import { formatCurrency } from '../../utils/valueFormatters';

const SalesPagePrintView = () => {
  const { cart, discount, paymentType, services, itemPackages } =
    useSelector<any>((state) => state.sales) as any;
  const { items } = useSelector<any>((state) => state.items) as any;

  const getItemPriceNet = (itemId: number) => {
    return itemId < 0
      ? cart.find((x) => x.itemId === itemId)?.sellPriceNet ?? 0
      : items.find((x) => x.itemId === itemId)?.net ?? 0;
  };

  const calculatefinalPrice = (isGross: boolean) => {
    let itemSum = 0;
    let serviceSum = 0;
    let itemPackageSum = 0;

    if (cart && cart) {
      itemSum =
        cart.reduce(
          (acc, cur) =>
            acc +
            getItemPriceNet(cur.itemId) *
              cur.amount *
              (cur.discount !== 0 ? 1 - cur.discount / 100 : 1),
          0
        ) *
        (discount !== 0 ? 1 - discount / 100 : 1) *
        (isGross ? 1.27 : 1);
    }

    if (services && services) {
      serviceSum = services.reduce(
        (sum, service) =>
          sum + isGross
            ? service.servicePriceNet * 1.27
            : service.servicePriceNet,
        0
      );
    }
    if (itemPackages && itemPackages) {
      itemPackageSum = itemPackages.reduce(
        (sum, itemPackage) =>
          sum + isGross ? itemPackage.sellPriceGross : itemPackage.sellPriceNet,
        0
      );
    }

    return itemSum + serviceSum + itemPackageSum;
  };

  const { printRef, handlePrint } = usePrintComponent();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Paper>
      <Tooltip title="Nyomtatás">
        <IconButton onClick={(e: any) => handlePrint()}>
          <Print />
        </IconButton>
      </Tooltip>
      <Grid container p={3} ref={printRef}>
        <Grid item xs={6}>
          <img src="letis_logo.png" alt="Letis" />
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={12}>
            <Typography variant="h5" align="right">
              Zárbolt & Kulcsmásoló - Letis Magyarország Kft.
            </Typography>{' '}
          </Grid>
          <Grid item xs={12}>
            <Typography align="right">1149 Budapest, Várna utca 5/B</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="right">+36 30 990 8102</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="right">www.kulcsmasolas.hu</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="right">Cégjegyzékszám: 01-09-992862</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="right">Adószám: 24151131-2-41</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <h1>Nyugta</h1>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Cikkszám</StyledTableCell>
                    <StyledTableCell align="left">
                      Termék megnevezése
                    </StyledTableCell>
                    <StyledTableCell align="right">Darabszám</StyledTableCell>
                    <StyledTableCell align="right">
                      Ár&nbsp;(nettó)
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Ár&nbsp;(bruttó)
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Kedvezmény&nbsp;(%)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map((row, index) => {
                    var item =
                      items.find((x: any) => x.itemId === row.itemId) ??
                      cart.find((x: any) => x.itemId === row.itemId);

                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {item.itemNumber}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.itemAlternateName !== '' &&
                          item.itemAlternateName !== null
                            ? item.itemAlternateName
                            : item.itemName}{' '}
                          <br />
                          {row.comment}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(
                            (getItemPriceNet(row.itemId) * row.amount).toFixed(
                              2
                            )
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(
                            (
                              getItemPriceNet(row.itemId) *
                              row.amount *
                              1.27
                            ).toFixed(2)
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.discount > 0 ? row.discount : ''}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {services.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                        ></StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                          <br />
                          {row.comment}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(row.servicePriceNet)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(row.servicePriceGross)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.discount > 0 ? row.discount : ''}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {itemPackages.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                        ></StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                          <br />
                          {row.comment}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(row.sellPriceNet)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatCurrency(row.sellPriceGross)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.discount > 0 ? row.discount : ''}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container item justifyContent="end" pt={5}>
          <Grid item p={2} style={{ borderStyle: 'groove' }}>
            {discount > 0 && (
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>Engedmény a végösszegből: {discount}%</Grid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Grid item>Fizetési mód: {paymentType}</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>Fizetendő(Nettó):</Grid>
              <Grid item>
                {formatCurrency(calculatefinalPrice(false).toFixed(2))}
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>Fizetendő(ÁFA): </Grid>
              <Grid item>
                {formatCurrency(
                  (
                    calculatefinalPrice(true) - calculatefinalPrice(false)
                  ).toFixed(2)
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>Fizetendő(Bruttó): </Grid>
              <Grid item>{formatCurrency(calculatefinalPrice(true))} </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SalesPagePrintView;
