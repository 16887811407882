import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Box,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import DataList from '../../../components/DataList';
import { formatCurrency } from '../../../utils/valueFormatters';

export default function ItemViewDialog(props: any) {
  const { open, handleClose, entity } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500, width: '100%' }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Termék adatai</DialogTitle>
      <DialogContent>
        <Grid container pt={2} spacing={2}>
          <Grid item xs={4}>
            <TextField
              value={entity.itemNumber}
              label="Cikkszám"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={entity.itemName}
              label="Megnevezés"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={entity.totalAmount}
              label="Rendelkezésre álló mennyiség az összes raktárban"
              fullWidth
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
            />
          </Grid>
          {checkPermission(['ItemStockView']) && (
            <Grid item xs={12}>
              <Box style={{ width: '100%' }} pt={3}>
                <Typography fontWeight="bold">
                  Elérhető mennyiség raktáronként
                </Typography>
                <DataList
                  rows={entity.itemStocks}
                  localStorageKey={'ItemViewDialogItemStocks'}
                  columns={[
                    {
                      field: 'warehouseName',
                      headerName: 'Raktár megnevezése',
                      width: 200,
                    },
                    {
                      field: 'amount',
                      headerName: 'Elérhető mennyiség',
                      width: 150,
                    },
                  ]}
                  getRowId={(row) => row.warehouseId}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box style={{ width: '100%' }} pt={3}>
              <Typography fontWeight="bold">Ártábla</Typography>
              <DataList
                rows={entity.itemPriceCategories}
                localStorageKey={'ItemViewDialogItemPriceCategories'}
                columns={[
                  {
                    field: 'priceCategoryName',
                    headerName: 'Árkategória',
                    width: 150,
                  },
                  {
                    field: 'net',
                    headerName: 'Nettó ár(Ft)',
                    width: 150,
                    valueFormatter: (params) =>
                      formatCurrency(params.value.toFixed(2)),
                  },
                  {
                    field: 'gross',
                    headerName: 'Bruttó ár(Ft)',
                    width: 150,
                    valueFormatter: (params) =>
                      formatCurrency(params.value.toFixed(2)),
                  },
                ]}
                getRowId={(row) => row.priceCategoryId}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          {checkPermission(['ItemEdit']) && (
            <Grid item>
              <Button
                variant="contained"
                component={NavLink}
                color="primary"
                to={`/wms/items/edit/${entity.itemId}`}
              >
                Szerkesztés
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button color="error" variant="outlined" onClick={handleClose}>
              Mégse
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
