import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import Copyright from './CopyRight';
import Header from './Header';
import Navigator from './Navigator';

import { Fullscreen, FullscreenExit, Add, Search } from '@mui/icons-material';
import { SpeedDial } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useCheckAlive from '../hooks/useCheckAlive';
import AppRoutes from './AppRoutes';
import SmartSearchDialog from '../components/SmartSearch/SmartSearchDialog';
let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    secondary: {
      light: '#ff6d00',
      main: '#ff3d00',
      dark: '#b22a00',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '*::-webkit-scrollbar': {
            width: '0px',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [smartSearchOpen, setSmartSearchOpen] = React.useState(false);

  const fullscreenMode = useSelector((state: any) => state.app.fullscreenMode);

  const dispatch = useDispatch();

  useCheckAlive();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        {!fullscreenMode && (
          <Box
            component="nav"
            sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onClick={handleDrawerToggle}
              />
            )}
            <Navigator
              PaperProps={{
                style: { width: drawerWidth },
              }}
              sx={{ display: { lg: 'block', xs: 'none' } }}
            />
          </Box>
        )}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {!fullscreenMode && <Header onDrawerToggle={handleDrawerToggle} />}
          <Box
            component="main"
            sx={{
              flex: 1,
              py: fullscreenMode ? 0 : 2,
              px: fullscreenMode ? 0 : 2,
              bgcolor: '#eaeff1',
            }}
          >
            <AppRoutes />
          </Box>
          {!fullscreenMode && (
            <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
              <Copyright />
            </Box>
          )}
        </Box>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{
            position: 'fixed',
            top: -8,
            right: 5,
            zIndex: 9919,
          }}
          FabProps={{
            size: 'small',
            sx: {
              color: fullscreenMode ? 'primary.main' : '#fff',
              boxShadow: 'none',
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent',
              },
              '&:focusVisible': {
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
              },
            },
          }}
          icon={fullscreenMode ? <FullscreenExit /> : <Fullscreen />}
          onClick={() => dispatch({ type: 'TOGGLE_FULLSCREEN_MODE' })}
        ></SpeedDial>
        <SpeedDial
          ariaLabel="Okos kereső"
          onClick={() => {
            setSmartSearchOpen(true);
          }}
          sx={{
            display: { xs: 'none', sm: 'fixed' },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          icon={<Search />}
        ></SpeedDial>
        <SmartSearchDialog
          open={smartSearchOpen}
          onClose={() => setSmartSearchOpen(false)}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
